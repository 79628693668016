package uk.mappa

class Constants {

    companion object {

        val startPitch: Double = 0.0
        val startBearing: Double = 0.0

        private val options = "?optimize=true"

        val KernewekStyle = "https://tiles.mappa.studio/styles/mappa-kernewek/style.json$options"
        val EnglishStyle = "https://tiles.mappa.studio/styles/mappa-sowsnek/style.json$options"

        val startLat = 50.2403308
        val startLong = -5.0768975
        val startZoom = 8.0
    }
}

enum class Language {
    Kernewek, English
}