import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import components.GeolocateControl
import components.Map
import components.NavigationControl
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.InternalCoroutinesApi
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposableInBody
import uk.mappa.*
import kotlin.js.json

//private val koin = initKoin(enableNetworkLogs = true).koin

@OptIn(ExperimentalComposeWebApi::class)
@InternalCoroutinesApi
fun main() {
//    val repo = koin.get<PeopleInSpaceRepositoryInterface>()
    val store = AppStore(Dispatchers.Default)
    renderComposableInBody {
        Style(TextStyles)
//        var people by remember { mutableStateOf(emptyList<Assignment>()) }

        LaunchedEffect(true) {
//            people = repo.fetchPeople()
        }
//
        var map: Map? = null

        val state by produceState(initialValue = AppState(), store) {
            store.observeState().collect {
                value = it
                map = Map(options = json(
                    "container" to "mapDiv",
                    "style" to it.style,
                    "center" to arrayOf(it.position.center.lng, it.position.center.lat),
                    "zoom" to it.position.zoom,
                    "pitch" to it.position.pitch,
                    "bearing" to it.position.bearing,
                    "failIfMajorPerformanceCaveat" to true,
                    "pixelRatio" to window.devicePixelRatio
                ))
                map?.addControl(
                    NavigationControl(options = json(
                            "visualizePitch" to true,
                            "showZoom" to true,
                            "showCompass" to true
                )), position = "top-right")

                map?.addControl(
                    GeolocateControl(options = json(
                )), position = "bottom-right")

                console.log("Map created")
//                map.on(type = "move", listener = {
////                    console.log("Camera moved to ${it.entries}")
//                })
            }
        }

        Div(attrs = {
            id("container")
            style {
                display(DisplayStyle.Flex)
                flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
                height(window.document.body?.scrollHeight?.px ?: 100.vh)
                backgroundColor(backgroundColor)
            }
        }) {

            Div(attrs = {
                id("headingDiv")
                style {
                    flexGrow(0)
                    flexShrink(1)
                    flexBasis("auto")
                    display(DisplayStyle.Flex)
                    flexFlow(FlexDirection.Row, FlexWrap.Nowrap)
                    marginLeft(1.cssRem)
                    marginRight(1.cssRem)
                    alignItems(AlignItems.Center)
                }
            }) {
                H1(attrs = {
                    classes(TextStyles.titleText)
                    style {
                        flexGrow(0)
                        flexShrink(1)
                        flexBasis("auto")
                    }
                }) {
                    Text("Mappa")
                }
                Div(attrs = {
                    style {
                        flexGrow(1)
                        flexShrink(1)
                        flexBasis("auto")
                    }
                })
                H3(attrs = {
                    classes(TextStyles.switchtext)
                    style {
                        flexGrow(0)
                        flexShrink(1)
                        flexBasis("auto")
                        marginRight(0.6.cssRem)
                    }
                }) {
                    Text(state.language.toString())
                }

                Label(attrs = {
                    id("toggleLang")
                    classes("switch")
                    style {
                        flexGrow(0)
                        flexShrink(1)
                        flexBasis("auto")
                    }
                }) {
                    Input(InputType.Checkbox, attrs = {
                        classes("checkbox")
                        checked(state.language == Language.Kernewek)
                        onClick {
                            map?.let {
                                val ll = it.getCenter()
                                store.dispatch(
                                    AppAction.ToggleLanguage(
                                    MapPosition(
                                        center = LatLong(lat = ll.lat.toDouble(), lng = ll.lng.toDouble()),
                                        zoom = it.getZoom().toDouble(),
                                        pitch = it.getPitch().toDouble(),
                                        bearing = it.getBearing().toDouble()
                                )))
                            }
                        }
                    })
                    Span(attrs = {
                        classes(setOf("slider", "round"))
                    })
                }
            }
            Div(attrs = {
                id("mapDiv")
                style {
                    flexGrow(1)
                    flexShrink(1)
                    flexBasis("auto")
                    width(100.vw)
                }
            })
//            if (window.navigator.userAgent.contains("Android")) {
//                Div(attrs = {
//                    id("appSuggesstion")
//                    style {
//                        flexGrow(0)
//                        flexShrink(1)
//                        flexBasis("auto")
//                        backgroundColor(backgroundColor)
//                    }
//                }) {
//                    H3(attrs = {
//                        classes(TextStyles.titleText)
//                        style {
//                            flexGrow(0)
//                            flexShrink(1)
//                            flexBasis("auto")
//                        }
//                    }) {
//                        Text("Use android app")
//                    }
//                }
//            }
        }
    }
}

val primaryColor = rgb(r = 248, g = 160, b = 20)
val backgroundColor = rgb(r = 44, g = 44, b = 44)

object TextStyles : StyleSheet() {

    val titleText by style {
        color(primaryColor)
        fontSize(1.6.cssRem)
        fontWeight(900)

        property(
            "font-family",
            "Gotham SSm A,Gotham SSm B,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif"
        )
    }

    val switchtext by style {
        color(primaryColor)
        fontSize(1.cssRem)
        fontWeight(500)

        property(
            "font-family",
            "Gotham SSm A,Gotham SSm B,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif"
        )
    }
}