package uk.mappa

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import uk.mappa.Language.English
import uk.mappa.Language.Kernewek
import kotlin.coroutines.CoroutineContext

sealed class AppAction : Action {
    data class ToggleLanguage(val position: MapPosition) : AppAction()
}

sealed class AppSideEffect : Effect {
    data class Error(val error: Exception) : AppSideEffect()
}

data class AppState(
    val language: Language = Kernewek,
    val style: String = Constants.KernewekStyle,
    val position: MapPosition = MapPosition()
) : State

data class LatLong(val lat: Double, val lng: Double)

data class MapPosition(
    val center: LatLong = LatLong(lat = Constants.startLat, lng = Constants.startLong),
    val zoom: Double = Constants.startZoom,
    val bearing: Double = Constants.startBearing,
    val pitch: Double = Constants.startPitch,
) {
    companion object {
        fun new() = MapPosition()
    }
}

class AppStore(coroutineDispatcher: CoroutineContext) : Store<AppState, AppAction, AppSideEffect>,
    CoroutineScope by CoroutineScope(coroutineDispatcher) {

    private val state = MutableStateFlow(AppState())

    internal val sideEffect = MutableSharedFlow<AppSideEffect>(replay = 1)

    override fun observeState(): StateFlow<AppState> = state

    override fun observeSideEffect(): Flow<AppSideEffect> = sideEffect

    override fun dispatch(action: AppAction) {
        val oldState = state.value

        val newState = when (action) {
            is AppAction.ToggleLanguage -> {
                oldState.copy(
                    position = action.position,
                    language = when(oldState.language) {
                        Kernewek -> English
                        English -> Kernewek
                    },
                    style = when(oldState.language) {
                        Kernewek -> Constants.EnglishStyle
                        English -> Constants.KernewekStyle
                    }
                )
            }
        }

        if (oldState != newState) {
            state.value = newState
        }
    }

    companion object {
        fun new() = AppStore(Dispatchers.Main)
    }
}